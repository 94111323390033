import React, { useEffect } from "react";
import Logo from "../assets/Logo.png";

// import AOS from "aos";
// import "aos/dist/aos.css";
function Header() {
	// useEffect(() => {
	// 	AOS.init();
	// }, []);
	return (
		<div
			// data-aos="fade-down"
			className="w-full h-[100%] flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-shrink-0 bg-white flex align-content-center justify-between items-center p-5 sm:p-1 md:p-4 lg:p-6 lg:px-20 xl:px-20 xl:p-6"
		>
			<img
				// data-aos="flip-left"
				// data-aos-easing="ease-out-cubic"
				// data-aos-duration={`${3000}`}
				className="w-[80px] sm:w-[140px] h-[60px] sm:h-[80px] flex-shrink-0"
				src={Logo}
				alt={"Logo"}
			/>
			<div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 pt-[10px] sm:pt-[20px] md:pt-[0px] lg:pt-[0px] xl:pt-[0px] gap-4 align-content-center justify-end items-end">
				{[
					{ name: "Home", link: "#Home" },
					{ name: "About Us", link: "#About" },
					{ name: "Projects", link: "#Projects" },
					{ name: "Services", link: "#Services" },
					{ name: "CEO Message", link: "#CEO" }
				].map((a, i) => (
					<a
						// data-aos="fade-up"
						// data-aos-duration={`${300 * (i + 1)}`}
						// data-aos-easing="ease-in-back"
						className=" cursor-pointer hover:border-b-[3px] border-primary text-center text-primary font-poppins text-xs font-[500] sm:text-xs md:text-lg lg:text-lg xl:text-lg leading-[125%]"
						href={a?.link}
					>
						{a?.name}
					</a>
				))}
			</div>
		</div>
	);
}

export default Header;
