import React, { useEffect } from "react";
import Image from "../assets/image 13.png";
import Image2 from "../assets/Rectangle 2 (1).png";

// import AOS from "aos";
// import "aos/dist/aos.css";

function Mission() {
	// useEffect(() => {
	// 	AOS.init();
	// }, []);
	return (
		<div className="relative overflow-hidden  flex md:flex-row flex-col justify-center align-center items-center gap-0 h-[110%] md:h-[100vh] xl:h-[80vh]  lg:h-[80vh]">
			<div
				// data-aos="fade-right"
				// data-aos-offset="200"
				// data-aos-easing="ease-in-sine"
				// data-aos-duration="1000"
				className="md:w-[20%] w-full flex flex-col items-center justify-center bg-no-repeat bg-cover bg-center md:h-[100%] h-[20vh]"
				style={{
					background: `url('${Image2}')`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover"
				}}
			>
				<div className="text-[#434343] md:w-[150%] w-full text-center  md:h-[20%] font-playfair text-2xl md:text-3xl lg:text-5xl font-extrabold transform md:rotate-[-90deg] flex-shrink-0">
					OUR MISSION
				</div>
			</div>
			<div
				// data-aos="fade-down"
				// data-aos-offset="200"
				// data-aos-easing="ease-in-sine"
				// data-aos-duration="3000"
				className="py-[15%] md:py-[0%] w-full h-[100%] md:w-[80%] flex flex-col gap-10 overflow-hidden items-center justify-center bg-no-repeat bg-cover bg-center h-[100%] px-[10px] xl:px-[80px] lg:px-[80px] md:px-[30px] sm:px-[20px]"
				style={{
					background: `url('${Image}')`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover"
				}}
			>
				<p className="text-white font-work-sans text-xs md:text-sm lg:text-md font-normal">
					Motiwala Builders is a leading provider of high-quality construction services in Pakistan,
					committed to excellence, innovation, and sustainability. We take pride in delivering outstanding results that exceed our customers' expectations and create long-lasting value
					for the communities we serve. Our team of skilled professionals, architects, engineers,
					and craftsmen work closely with clients to understand their unique needs, preferences,
					and aspirations, and bring them to life through creative, efficient, and reliable building
					solutions
				</p>
				<p className="text-white font-work-sans text-xs md:text-sm lg:text-md font-normal">
					Our mission is to build more than just structures. We aim to create exceptional living
					environments that inspire and elevate the human experience. From luxurious homes to
					commercial spaces, from industrial projects to public infrastructure, we approach every
					project with passion, creativity, and attention to detail. We utilize the latest technologies,
					materials, and construction techniques to ensure that our buildings are not only beautiful
					but also energy-efficient, durable, and environmentally friendly. At Motiwala Builders,
					we believe that our work is not just about erecting buildings but about enriching lives,
					fostering connections, and creating a better future for generations to come.
				</p>
				<p className="text-white font-work-sans text-xs md:text-sm lg:text-md font-normal">
					As a company, we are dedicated to upholding the highest standards of ethics,
					professionalism, and customer service. We believe in transparency, honesty, and
					accountability in all our business dealings. We are committed to promoting a culture of
					continuous learning, innovation, and collaboration, where every member of our team is
					valued, respected, and empowered to contribute to our collective success. Our goal is to
					be recognized as a trusted and respected leader in the construction industry, renowned
					for our quality, reliability, and customer satisfaction
				</p>
			</div>
		</div>
	);
}

export default Mission;
