import React, { useState } from "react";

import axios from 'axios';
import swal from 'sweetalert'


function Query() {
	const [state, setState] = useState({
		name: "Subscribe",
		email: "",
		subject: "Subscribe to NewsLetter",
		message: "Subscribe to NewsLetter",
	});

	function handleChange(name, value) {
		setState({
			...state,
			[name]: value
		})
	};



	const SendMessage = async () => {
		try {
			await axios.post(`https://motiwalabuilders.online/ContactForm`, {
				...state,
				host: "Shared87.accountservergroup.com",
				port: 465,
				user: "help@motiwalabuilders.com",
				pass: "wearebuilders",
				from: "help@motiwalabuilders.com",
				to: "subscribe@motiwalabuilders.com",
			}).then((res) => {
				if (res?.data?.status == 200) {
					swal({
						text: "Message was Sent To Clinet Please Wait For Response",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
					setState({})
				}
			}).catch((err) => {
				console.log(err)
				swal({
					text: "There was some Error",
					button: {
						text: "Ok",
						closeModal: true
					},
					icon: "error",
					time: 3000
				});
			});

		} catch (error) {
			console.log('Error', error);
		}
	};

	return (
		<div className="relative overflow-hidden my-[5%] flex flex-col justify-start align-start items-start md:px-[10%] px-[2%]">
			<div className="rounded-[16px] bg-[#071114] w-full  px-[10px] lg:px-[105px] xl:px-[120px] py-[30px] lg:py-[50px] xl:py-[60px] flex flex-col justify-center items-center gap-10">
				<p className="text-white text-center font-inter text-lg font-bold leading-normal capitalize">
					Contact us today and let's craft your extraordinary living story together.
				</p>
				<p className="text-white text-center font-inter text-base md:text-lg font-light leading-normal">
					Reach out, and let's turn your dreams into an address. Your journey to exceptional living
					starts with a simple 'Hello'!
				</p>
				<div className="rounded-full w-full lg:w-[70%] px-[10px] md:py-[15px] py-[5px] bg-white flex flex-row justify-center ">
					<input
						className="rounded-full outline-none text-black font-inter text-base font-thin w-[80%]"
						onChange={(e) => handleChange("email", e.target.value)}
						value={state?.email}
						type="text"
					/>
					<button
						onClick={() => {
							SendMessage()
						}}
						className="w-[30%] md:w-[131px] h-[48px] p-[10px 20px] flex-shrink-0 rounded-[50px] bg-[#434343] text-white font-inter text-xs md:text-base font-semibold leading-normal">
						Subscribe{" "}
					</button>
				</div>
			</div>
		</div>
	);
}

export default Query;
